import React from 'react';
import Link from "next/link";
import Image from "next/image";

function Footer(props) {
	return (
		<footer className="site-footer center-content-mobile bg-[#1e2238] text-white">
			<div className="container mx-auto">
				<div className="grid grid-cols-1 gap-1 text-center sm:text-left sm:grid-cols-4 sm:gap-4 py-8">
					<div className="col-12 col-sm-3">
						<h4 className="text-2xl mb-3">Company</h4>
						<ul className="text-sm text-[#8f96b0] mb-10 sm:mb-0">
							<li className="mb-3">
								<Link href={process.env.NEXT_PUBLIC_ORIGINAL_URL + '/about'} className="text-decoration-none">
									About
								</Link>
							</li>
							<li className="mb-3">
								<Link href={process.env.NEXT_PUBLIC_BLOG_URL} className="text-decoration-none">
									Blog
								</Link>
							</li>
							<li className="mb-3">
								<Link href={process.env.NEXT_PUBLIC_ORIGINAL_URL + '/affiliate'} className="text-decoration-none">
									Affiliate Program
								</Link>
							</li>
							<li className="mb-3">
								<Link href={process.env.NEXT_PUBLIC_UPDATES_URL + '/'} className="text-decoration-none">
									Updates
								</Link>
							</li>
							<li className="mb-3">
								<Link href={'mailto:' + process.env.NEXT_PUBLIC_CONTACT_EMAIL} className="text-decoration-none">
									Contact
								</Link>
							</li>
						</ul>
					</div>
					<div className="col-12 col-sm-3">
						<h4 className="text-2xl mb-3">Resources</h4>
						<ul className="text-sm text-[#8f96b0] mb-10 sm:mb-0">
							<li className="mb-3">
								<Link href={process.env.NEXT_PUBLIC_ORIGINAL_URL + '/about/brand-assets'} className="text-decoration-none">
									Brand Assets
								</Link>
							</li>
						</ul>
					</div>
					<div className="col-12 col-sm-3">
						<h4 className="text-2xl mb-3">Legal</h4>
						<ul className="text-sm text-[#8f96b0] mb-10 sm:mb-0">
							<li className="mb-3">
								<Link href={process.env.NEXT_PUBLIC_ORIGINAL_URL + '/legal/terms'} className="text-decoration-none">
									Terms of Service
								</Link>
							</li>
							<li className="mb-3">
								<Link href={process.env.NEXT_PUBLIC_ORIGINAL_URL + '/legal/privacy'} className="text-decoration-none">
									Privacy Policy
								</Link>
							</li>
							<li className="mb-3">
								<Link href={process.env.NEXT_PUBLIC_ORIGINAL_URL + '/legal/cookies'} className="text-decoration-none">
									Cookie Policy
								</Link>
							</li>
							<li className="mb-3">
								<Link href={process.env.NEXT_PUBLIC_ORIGINAL_URL + '/legal/data-removal'} className="text-decoration-none">
									Data Removal
								</Link>
							</li>
						</ul>
					</div>
					<div className="col-12 col-sm-3">
						<h4 className="text-2xl mb-3">Help</h4>
						<ul className="text-sm text-[#8f96b0] mb-10 sm:mb-0">
							<li className="mb-3">
								<Link href="https://pagename.zendesk.com/hc/en-us" className="text-decoration-none">
									Support
								</Link>
							</li>
						</ul>
					</div>
				</div>
				<div className="site-footer-inner pt-3">
					<div className="mb-5 flex justify-between">
						<div className="brand">
							<Link href={process.env.NEXT_PUBLIC_ORIGINAL_URL}>
								<Image
									src="/images/pagename-logo-color-and-white.png"
									height={32}
									width={175}
									style={{alignSelf: 'center'}}
									alt="PageName Logo"
								/>
							</Link>
						</div>
						<ul className="list-reset">
							<li className="inline-block mx-3">
								<a href="https://facebook.com/pagenamer" rel="noreferrer" target="_blank" className="text-[#3f52ff]">
									<svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill="#3f52ff">
										<title>Facebook</title>
										<path
											d="M6.023 16L6 9H3V6h3V4c0-2.7 1.672-4 4.08-4 1.153 0 2.144.086 2.433.124v2.821h-1.67c-1.31 0-1.563.623-1.563 1.536V6H13l-1 3H9.28v7H6.023z"/>
									</svg>
								</a>
							</li>
							<li className="inline-block mx-3">
								<a href="https://twitter.com/pagenamer" rel="noreferrer" target="_blank" >
									<svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill="#3f52ff">
										<title>Twitter</title>
										<path
											d="M16 3c-.6.3-1.2.4-1.9.5.7-.4 1.2-1 1.4-1.8-.6.4-1.3.6-2.1.8-.6-.6-1.5-1-2.4-1-1.7 0-3.2 1.5-3.2 3.3 0 .3 0 .5.1.7-2.7-.1-5.2-1.4-6.8-3.4-.3.5-.4 1-.4 1.7 0 1.1.6 2.1 1.5 2.7-.5 0-1-.2-1.5-.4C.7 7.7 1.8 9 3.3 9.3c-.3.1-.6.1-.9.1-.2 0-.4 0-.6-.1.4 1.3 1.6 2.3 3.1 2.3-1.1.9-2.5 1.4-4.1 1.4H0c1.5.9 3.2 1.5 5 1.5 6 0 9.3-5 9.3-9.3v-.4C15 4.3 15.6 3.7 16 3z"/>
									</svg>
								</a>
							</li>
							<li className="inline-block mx-3">
								<a href="https://instagram.com/pagename" rel="noreferrer" target="_blank">
									<svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill="#3f52ff">
										<title>Instagram</title>
										<g>
											<circle cx="12.145" cy="3.892" r="1"/>
											<path
												d="M8 12c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4zm0-6c-1.103 0-2 .897-2 2s.897 2 2 2 2-.897 2-2-.897-2-2-2z"/>
											<path
												d="M12 16H4c-2.056 0-4-1.944-4-4V4c0-2.056 1.944-4 4-4h8c2.056 0 4 1.944 4 4v8c0 2.056-1.944 4-4 4zM4 2c-.935 0-2 1.065-2 2v8c0 .953 1.047 2 2 2h8c.935 0 2-1.065 2-2V4c0-.935-1.065-2-2-2H4z"/>
										</g>
									</svg>
								</a>
							</li>
						</ul>
					</div>
					<div className="mt-5 text-[#8f96b0] text-sm pb-20">
						<div className="footer-copyright">&copy; PageName {new Date().getFullYear()}, all rights reserved</div>
					</div>
				</div>
			</div>
		</footer>
	);
}

export default Footer;
